import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, effect, inject } from '@angular/core';
import { NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbsStore } from '@sds/breadcrumbs';
import {
  AnalyticsService,
  ConfigProperty,
  ConfigService,
  DataLayerService,
  Language,
  LocaleStore,
  PortalSettingsStore,
  SharedModule,
  SharedStore,
  UserStore,
  hashCode,
  hashCodeRandom,
  PageContextProviderService
} from '@sds/shared';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { ThemeHandlerComponent } from './theme-handler/theme-handler.component';

@Component({
  standalone: true,
  selector: 'sds-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, ThemeHandlerComponent]
})
export class AppComponent implements OnInit {
  private breadcrumbsStore = inject(BreadcrumbsStore);
  private pageContextProvider = inject(PageContextProviderService);
  private userStore = inject(UserStore);
  private localeStore = inject(LocaleStore);
  private sharedStore = inject(SharedStore);
  private portalSettingsStore = inject(PortalSettingsStore);
  private destroyRef = inject(DestroyRef);
  private translateService = inject(TranslateService);
  private router = inject(Router);
  private swUpdate = inject(SwUpdate);
  private analyticsService = inject(AnalyticsService);
  private configService = inject(ConfigService);
  private dataLayer = inject(DataLayerService);
  public loader = inject(LoadingBarService);

  constructor() {
    effect(() => this.translateService.addLangs(this.localeStore.languages()));
    effect(() => {
      const user = this.userStore.user();
      if (user) {
        if (user.plantCount) {
          this.dataLayer.pushToDataLayer({ numberOfPlants: user.plantCount });
        }
        if (user.userGroup) {
          this.dataLayer.pushToDataLayer({ userGroup: user.userGroup });
        }

        if (user.userRoles) {
          this.dataLayer.pushToDataLayer({ userRoles: user.userRoles.map(role => role.id) });
        }

        this.dataLayer.pushToDataLayer({ ffufId: user.id });
        this.dataLayer.pushToDataLayer({ hashedId1: hashCode(user.id) });
        this.dataLayer.pushToDataLayer({ hashedId2: hashCodeRandom(user.id) });

        if (this.configService.get(ConfigProperty.VersionNumber)) {
          this.dataLayer.pushToDataLayer({
            versionNumber: this.configService.get(ConfigProperty.VersionNumber)
          });
        }
      }
      this.dataLayer.pushToDataLayer({ defaultCountry: this.portalSettingsStore.portal().defaultCountry });
      this.analyticsService.pageView(true, {});
    });
  }

  ngOnInit() {
    if (environment.production) {
      this.dataLayer.pushToDataLayer({ env: 'prod' });
    } else {
      this.dataLayer.pushToDataLayer({ env: 'dev' });
    }

    const defaultLang = Language.EN_GB;
    this.translateService.setDefaultLang(defaultLang);

    this.router.events.subscribe(event => {
      if (event instanceof ResolveEnd) {
        this.breadcrumbsStore.setBreadcrumbs([]);
        this.pageContextProvider.setPageContext(null);
        this.analyticsService.pageView(false, {});
      }
    });

    this.swUpdate.versionUpdates
      .pipe(
        tap(event => console.log(event)),
        takeUntilDestroyed(this.destroyRef),
        filter(event => event.type === 'VERSION_READY')
      )
      .subscribe(event => {
        this.sharedStore.setAvailableUpdate(event as VersionReadyEvent);
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        filter(() => !!this.sharedStore.availableUpdate()),
        tap(event => console.log('Applying update: ', event)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => document.location.reload());
  }
}
